/*eslint-env node, browser*/

/**
 * Generated by PluginGenerator 2.16.0 from webgme on Thu Jul 12 2018 15:47:27 GMT-0500 (Central Daylight Time).
 * A plugin that inherits from the PluginBase. To see source code documentation about available
 * properties and methods visit %host%/docs/source/PluginBase.html.
 */

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        define([
            'plugin/PluginConfig',
            'text!./metadata.json',
            'plugin/PluginBase',
            'plugins/ModelicaCodeGenerator/ModelicaCodeGenerator/ModelicaCodeGenerator',
        ], factory);
    } else if (typeof module === 'object' && module.exports) {
        module.exports = factory(
            require('webgme-engine/src/plugin/PluginConfig'),
            require('./metadata.json'),
            require('webgme-engine/src/plugin/PluginBase'),
            require('../ModelicaCodeGenerator/ModelicaCodeGenerator'),
        );
    }
}(function (PluginConfig,
            pluginMetadata,
            PluginBase,
            ModelicaCodeGenerator) {
    'use strict';

    pluginMetadata = typeof pluginMetadata === 'string' ? JSON.parse(pluginMetadata) : pluginMetadata;

    const DOMAIN_MAP = {
        'Modelica.Blocks.Continuous': 'Modia.Blocks',
        'Modelica.Blocks.Sources ': 'Modia.Blocks',
        'Modelica.Electrical.Analog.Basic': 'Modia.Electric',
        'Modelica.Electrical.Analog.Sources': 'Modia.Electric',
        'Modelica.Mechanics.Rotational.Components': 'Modia.Rotational',
        'Modelica.Mechanics.Rotational.Sensors': 'Modia.Rotational',
    };

    /**
     * Initializes a new instance of ModiaCodeGenerator.
     * @class
     * @augments {PluginBase}
     * @classdesc This class represents the plugin ModiaCodeGenerator.
     * @constructor
     */
    function ModiaCodeGenerator() {
        // Call base class' constructor.
        PluginBase.call(this);
        this.pluginMetadata = pluginMetadata;
    }

    /**
     * Metadata associated with the plugin. Contains id, name, version, description, icon, configStructue etc.
     * This is also available at the instance at this.pluginMetadata.
     * @type {object}
     */
    ModiaCodeGenerator.metadata = pluginMetadata;

    // Prototypical inheritance from PluginBase.
    ModiaCodeGenerator.prototype = Object.create(PluginBase.prototype);
    ModiaCodeGenerator.prototype.constructor = ModiaCodeGenerator;

    /**
     * Main function for the plugin to execute. This will perform the execution.
     * Notes:
     * - Always log with the provided logger.[error,warning,info,debug].
     * - Do NOT put any user interaction logic UI, etc. inside this method.
     * - callback always has to be called even if error happened.
     *
     * @param {function(string, plugin.PluginResult)} callback - the result callback
     */
    ModiaCodeGenerator.prototype.main = function main(callback) {
        const {logger} = this;

        const supportedDomains = Object.keys(DOMAIN_MAP);

        function getModiaURI(uri) {
            for (let i = 0; i < supportedDomains.length; i += 1) {
                if (uri.indexOf(supportedDomains[i]) === 0) {
                    return uri.replace(supportedDomains[i], DOMAIN_MAP[supportedDomains[i]]);
                }
            }

            logger.error('Domain not supported', uri, '. Will continue..');
            return uri;
        }

        function getModiaFileContent(modelJson) {
            let moFile = 'using Modia';
            moFile += `\n@model ${modelJson.name} begin`;

            modelJson.components
                .sort((a, b) => {
                    if (a.URI > b.URI) {
                        return 1;
                    } else if (a.URI < b.URI) {
                        return -1;
                    } else if (a.name > b.name) {
                        return 1;
                    } else if (a.name < b.name) {
                        return -1;
                    }

                    return 0;
                })
                .forEach((data) => {
                    const params = Object.keys(data.parameters);
                    moFile += `\n ${data.name}=${getModiaURI(data.URI)}`;
                    if (params.length > 0) {
                        moFile += '(';
                        params.forEach((p, idx) => {
                            moFile += `${p}=${data.parameters[p]}, `;
                            if (idx === params.length - 1) {
                                if (data.modifiers) {
                                    moFile += data.modifiers;
                                } else {
                                    moFile = moFile.slice(0, -2);
                                }
                            }
                        });

                        moFile += ')';
                    } else if (data.modifiers) {
                        moFile += `(${data.modifiers})`;
                    }

                    moFile += ';';
                });

            moFile += '\n@equations begin';

            modelJson.connections
                .sort((a, b) => {
                    if (a.src > b.src) {
                        return 1;
                    } else if (a.src < b.src) {
                        return -1;
                    } else if (a.dst > b.dst) {
                        return 1;
                    } else if (a.dst < b.dst) {
                        return -1;
                    }

                    return 0;
                })
                .forEach((data) => {
                    moFile += `\n    connect(${data.src}, ${data.dst})`;
                });

            moFile += '\n  end';
            moFile += '\nend';

            logger.debug(moFile);

            return moFile;
        }

        // 1) Retrieve an object will all nodes in the subtree of activeNode
        this.loadNodeMap(this.activeNode)
            .then((nodes) => {
                const modelJson = ModelicaCodeGenerator.prototype.extractModelData.call(this, nodes);
                // 10) We turn the data-structure into a string (indentation 2) and log it
                logger.debug('modelJson', JSON.stringify(modelJson, null, 2));
                const moFile = getModiaFileContent(modelJson);
                this.moFile = moFile;

                logger.debug('moFile', moFile);
                this.createMessage(this.activeNode, moFile);
                // 12) Add the modia file content as a file on the blobstorage.
                return this.blobClient.putFile(`${modelJson.name}.jl`, moFile);
            })
            .then((metadataHash) => {
                logger.debug(metadataHash);
                // 13) Link the uploaded file (using the hash) from the plugin result.
                this.result.addArtifact(metadataHash);
                this.result.setSuccess(true);
                callback(null, this.result);
            })
            .catch((err) => {
                logger.error(err.stack);
                callback(err, this.result);
            });
    };

    return ModiaCodeGenerator;
}));
